.assignFolder {
    width: 80%;
    height: 100vh;
    margin: 0 auto;
    background: $secondary;
    border: 1px solid $secondary;
    box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.22);
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    &--header {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    &--container {
        width: 100%;
        height: auto;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    &--toggle-container {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        margin: 1rem 0;
    }
    &--toggle-btn {
        flex: 1;
        height: 44px;
        background-color: #eaeaea;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        letter-spacing: -0.231818px;
        text-transform: uppercase;
        color: #010101;
        transition: all ease-in-out 0.3s;
    }
    &--toggle-btn-active {
        background-color: #2174a1;
        color: #fff;
        transition: all ease-in-out 0.3s;
    }
    &--title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.231818px;
        text-transform: uppercase;
        color: #000000;
    }
    &--folderList-container {
        width: 100%;
        height: 60vh;
        box-shadow: 0px 4px 34px rgba(33, 116, 161, 0.12);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: auto;
        position: relative;
    }
    &--folderList-header {
        width: 100%;
        height: 37px;
        background: #2174a1;
        border: 1px solid #2174a1;
        box-sizing: border-box;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        select {
            border: none;
            background-color: transparent;
            outline: none;
            font-family: "Poppins";
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 21px;
            letter-spacing: -0.231818px;
            text-transform: uppercase;
            color: #ffffff;
        }
    }
    &--folderList {
        border: none;
        background-color: transparent;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: -0.231818px;
        text-transform: uppercase;
        color: #000000;
        img {
            margin-right: 0.5rem;
        }
    }
    &--folderList-badge {
        width: 20px;
        height: 20px;
        border: 1px solid #b8b8b8;
        margin: 0.5rem;
        transition: ease-in-out all 0.3s;
    }
    &--folderList-badge-active {
        background-color: $primaryLight;
        border: none !important;
    }
    &--information {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    &--forms {
        width: 46%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
    }
}

@media only screen and (max-width: $xs) {
    .assignFolder {
        width: 100%;
        box-shadow: none;
        padding: 0 1rem;
        &--toggle-btn {
            font-size: 10px;
        }
        &--information {
            flex-direction: column;
        }
        &--forms {
            width: 100%;
        }
    }
}
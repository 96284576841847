.dashboard {
  a {
    color: inherit;
    &:hover {
      text-decoration: inherit;
      color: inherit;
    }
  }
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0;
  &--btn_edit {
    margin-left: auto;
    background-color: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.231818px;
    text-transform: capitalize;
    color: $primaryLight;
    border: none;
    cursor: pointer;
  }
  &--folder_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
  &--folder_btnAdd {
    width: 160px;
    height: 118px;
    background-color: transparent;
    background-image: url('../../../assets/images/folder-add.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  &--folder_btnBg {
    position: absolute;
    top: 0;
    left: 0;
  }
  &--folder_icon {
    position: relative;
    z-index: 10;
    margin-bottom: 0.5rem;
    color: $secondary;
  }
  &--folder_btnLabel {
    width: 100%;
    position: relative;
    z-index: 10;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.231818px;
    text-transform: uppercase;
    color: $secondary;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;
  }
  &--otherFolder_btn {
    width: 160px;
    height: 118px;
    background-color: transparent;
    background-image: url('../../../assets/images/other-folder.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
  &--folder_btnLabel2 {
    position: relative;
    z-index: 10;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.231818px;
    text-transform: uppercase;
    color: $secondary;
  }
  &--otherFolder_btnDelete {
    position: absolute;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    color: $secondary;
    border-radius: 50%;
    right: 0.5rem;
    top: 1rem;
    z-index: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
  &--input_modal {
    width: 100%;
    height: 50px;
    border: 1px solid $primaryLight;
    border-radius: 8px;
    padding: 0 1rem;
    margin: 1rem 0;
    background-color: #f2f2f2;
  }
  &--modal_btn {
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid $primaryLight;
    transition: all ease-in-out 0.3s;
    color: $secondary;
    background-color: $primaryLight;
    &:hover {
      color: $primaryLight;
      background-color: $secondary;
    }
  }
  &--inputLayout {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0.5rem 0;
    label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.231818px;
      color: #a8a8a8;
      margin-left: 1rem;
      text-transform: capitalize;
    }
    input {
      box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.12);
      width: 100%;
      height: 37px;
      border: none;
      padding: 0 1rem;
      &::placeholder {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 32px;
        color: $black;
      }
    }
  }
  &--btns-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $grey;
    margin-top: 1rem;
    overflow: hidden !important;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  &--btn-annuler,
  &--btn-adduser {
    flex: 1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.231818px;
    text-transform: uppercase;
    cursor: pointer;
  }
  &--btn-adduser {
    color: #40b144;
    border-left: 1px solid $grey;
  }
  &--btn-annuler {
    color: $primaryLight;
  }
}

//RESPONSIVE
@media only screen and (max-width: $xs) {
  .dashboard {
    &--folder_btnAdd,
    &--otherFolder_btn {
      width: 140px;
      height: 125px;
      background-size: contain;
    }
    &--otherFolder_btnDelete {
      top: 2rem;
    }
  }
}

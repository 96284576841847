.login {
  a {
    color: inherit;
    &:hover {
      text-decoration: inherit;
      color: inherit;
    }
  }
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  background-image: url('../../assets/images/bg-login.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &--form {
    width: max-content;
    height: 100vh;
    background-color: $primary;
    color: $secondary;
    padding: 5rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  &--inputs {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 2rem 0;
  }
  &--inputs_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1rem 0;
  }
  &--inputs_label,
  &--inputs_forget-text {
    font-family: 'Proxima Nova Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: $secondary;
  }
  &--inputs_style {
    width: 100%;
    height: 45px;
    border: 1.5px solid rgba(255, 255, 255, 0.4);
    box-shadow: inset 0px 2px 4px rgba(190, 221, 241, 0.14);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    margin-top: 0.5rem;
    input {
      flex: 1;
      height: 100%;
      border: none;
      background-color: transparent;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 17px;
      color: $secondary;
      padding: 0 0.5rem;
    }
  }
  &--inputs_showPassword {
    cursor: pointer;
  }
  &--inputs_forget-text {
    margin-left: auto;
  }
  &--button {
    width: 100%;
    height: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    border-radius: 60px;
    background-color: $primary;
    border: 1px solid $secondary;
    font-family: 'Proxima Nova Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.231818px;
    text-transform: uppercase;
    padding: 1rem;
    margin-bottom: 0.5rem;
    color: $secondary;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: $secondary;
      color: $primary;
    }
  }
}

//RESPONSIVE
@media only screen and (max-width: $xs) {
  .login {
    background-color: $primary;
    background-image: none;
    width: 100wh;
    height: 100vh;
    &--logo {
      width: 200px;
    }
    &--form {
      width: 90%;
      padding: 5rem 1rem;
      justify-content: center;
    }
  }
}

.inputLayout {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0.5rem 0;
    label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.231818px;
        color: #a8a8a8;
        margin-left: 1rem;
        text-transform: capitalize;
    }
    input {
        box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.12);
        width: 100%;
        height: 37px;
        border: none;
        padding: 0 1rem;
        &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 32px;
            color: $black;
        }
    }
}
.mainLayout {
    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
    width: 80%;
    height: 100vh;
    margin: 0 auto;
    background: $secondary;
    border: 1px solid $secondary;
    box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.22);
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    &--header {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
    &--logo {
        margin-top: 3rem;
    }
    &--button_link {
        width: 160px;
        height: 86px;
        padding: 1rem;
        display: flex;
        align-items: flex-end;
        text-align: center;
        justify-content: center;
        border: none;
        background-color: $secondary;
        box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.12);
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: -0.231818px;
        text-transform: uppercase;
        color: $black;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: $primaryLight;
            color: $secondary;
        }
        a {
            color: inherit;
            &:hover {
                text-decoration: inherit;
                color: inherit;
            }
        }
    }
    &--link-active {
        background-color: $primaryLight !important;
        color: $secondary !important;
    }
    &--container {
        width: 100%;
        height: auto;
        overflow-y: auto;
        padding: 0 1rem;
    }
}

//RESPONSIVE
@media only screen and (max-width: $xs) {
    .mainLayout {
       
        width: 100%;
        box-shadow: none;
        padding: 0;
        &--header {
           // height: 100px;
            background-color: $secondary;
            align-items: flex-end;
            box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.12);
            margin-bottom: 1rem;
            margin-top: 4rem;

             a {
            flex: 1;
        }
        }
        &--logo {
            width: 60px;
            position: absolute;
            left: 50%;
            top: 1rem;
            margin-top: 0;
            transform: translateX(-50%);
        }
        &--button_link {
            font-size: 10px;
            height: 40px;
            width: 100%;
            margin: 0;
            flex: 1;
            padding: none;
            align-items: center;
            box-shadow: none;
        }
    }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    src: url("../assets/fonts/ProximaNova-Regular.otf");
}

@font-face {
    font-family: "Proxima Nova Bold";
    font-style: normal;
    src: url("../assets/fonts/FontsFree-Net-proxima_nova_bold-webfont.ttf");
}
.logout {
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  width: 60px;
  height: 60px; 
  background-color: $primaryLight;
  display: flex;
  align-items: center;
  justify-content: center; 
  color: #fff;
  border-radius: 50%;
  transition: all ease-in-out .3s;
  cursor: pointer;
  z-index: 1000;
}

.logout:hover{
  transform: scale(1.1)
}

//RESPONSIVE
@media only screen and (max-width: $xs){
  .logout{
    top: .5rem;
    left: .5rem;
    width: 45px;
    height: 45px;
  }
}
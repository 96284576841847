//TYPOGRAPIE
//COLOR
.red {
  color: $red !important;
}

//FORM
.search {
  width: 96%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $secondary;
  margin-right: auto;
  box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.12);
  margin-bottom: 2rem;
  input {
    flex: 1;
    height: 100%;
    border: none;
    background-color: transparent;
    padding: 0 1rem;
    &::placeholder {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 32px;
      color: $black;
    }
  }
  button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $primaryLight;
    color: $secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(25px);
    border: 1px solid $secondary;
    box-shadow: 0px 1px 14px rgba(82, 164, 206, 0.35);
    cursor: pointer;
    transition: ease-in-out all 0.3s;
    &:hover {
      border-color: $primaryLight;
      background-color: $secondary;
      color: $primaryLight;
    }
  }
}

.titleLeft {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: -0.231818px;
  text-transform: uppercase;
  color: $black;
  position: relative;
  span {
    flex: 1;
    height: 1px;
    background-color: $primaryLight;
    margin-left: 1rem;
  }
}

//GRID
//LAYOUT

/***Modal***/

.modal-header {
  border: none !important;
}

.modal-title {
  flex: 1;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.231818px;
  text-transform: uppercase;
  color: $black;
}

.modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
}

.modal-content {
  border: none !important;
  box-shadow: 0px 4px 24px rgba(141, 141, 141, 0.25) !important;
  border-radius: 30px !important;
}

.modal-dialog {
  min-width: 300px !important;
  max-width: auto !important;
}

//BUTTON
.btnAdd {
  width: 250px;
  height: 55px;
  background: $primaryLight;
  box-shadow: 0px 4px 34px rgba(33, 116, 161, 0.12);
  border-radius: 70px;
  border: 1px solid $primaryLight;
  color: $secondary;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  transition: all 0.3s ease-in-out;
  i {
    margin-right: 1rem;
  }
  &:hover {
    background-color: $secondary;
    color: $primaryLight;
  }
}

.btn_edit {
  margin-left: auto;
  background-color: transparent;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.231818px;
  text-transform: capitalize;
  color: $primaryLight;
  border: none;
  cursor: pointer;
}

.btn-edit {
  background-color: $primaryLight;
  box-shadow: 0px 4px 34px rgba(33, 116, 161, 0.12);
  border-radius: 70px;
  border: 1px solid $primaryLight;
  width: 200px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  margin: 1rem 0 1rem auto;
  &:hover {
    background-color: $secondary;
    color: $primaryLight;
  }
}

.return-btn {
  background-color: transparent;
  border: none;
  margin: 1rem 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: -0.231818px;
  text-transform: uppercase;
  color: $black;
  display: flex;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }
}

.fileView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  border-radius: 6px;
  position: relative;
  border: 2px dashed $grey;

  &--img {
    margin-bottom: 0.5rem;
    width: 90%;
    object-fit: contain;
  }
  &--name {
    width: 90%;
    text-align: center;
    line-height: 1.1rem;
    margin: 0;
    word-wrap: break-word;
    font-weight: 600;
  }
  &--deleteBtn {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: crimson;
    border: 0;
    border-radius: 15px;
    outline: inherit;
    cursor: pointer !important;
    color: white;
    font-weight: 700;
  }
}

.version {
  font-size: 12px;
  font-weight: 600;
}
//BLOCK

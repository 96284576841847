.selectedFolder {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  &--files-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
  &--dropzone {
    width: 300px;
    height: 200px;
    border: 2px dashed #d3dbd1;
    border-style: dashed;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &--preview-container,
  &--file-preview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #425137;
    }
    img {
      margin-bottom: 1rem;
    }
  }
  &--dropzone-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #425137;
    text-align: center;
    span {
      color: $primaryLight;
    }
  }
  &--dropzone-text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #76806b;
  }
  small {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $primaryLight;
    font-weight: 500;
  }

  &--btn-download {
    min-width: 185px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primaryLight;
    box-shadow: 0px 4px 5px rgba(134, 134, 134, 0.2);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: $secondary;
    margin: 1rem;
    border: none;
    border-radius: 70px;
    transition: all 0.3s ease-in-out;
    padding: auto 1rem;
    i {
      margin-right: 0.5rem;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
  &--btn-download-gray {
    width: 160px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #eeeeee;
    box-shadow: 0px 4px 5px rgba(134, 134, 134, 0.2);
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #0c0c0c;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }

  &--list-container {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding: 0 0.5rem;
  }
  &--list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  &--list {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $secondary;
    box-shadow: 0px 1px 4px rgba(106, 106, 106, 0.25);
    border-radius: 50px;
    padding: 0 1rem;
    margin: 0.7rem 0;
  }
  &--list-name,
  &--list-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.231818px;
    text-transform: capitalize;
    color: $black;
  }
  &--list-name {
    width: 55%;
    padding-right: 0.5rem;
    word-wrap: break-word;
  }
  &--list-date {
    flex: 1;
  }
  &--list-icon {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }
  &--list-btnDownload {
    display: flex;
    align-items: center;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }
  &--list-check {
    width: 18px;
    height: 18px;
    padding: 0.5rem;
    display: inline-block;
    background-color: white;
    color: $secondary;
    border: 1px solid $grey;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: 0.3s;
    &:hover {
      border-color: $primary;
    }
  }
  &--list-checked {
    background-color: $primaryLight !important;
  }
  &--downloadAllBtn {
    padding: 0.3rem 1rem;
    border-radius: 8px;
    background-color: $primaryLight;
    color: $secondary;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 12px;
    margin-left: 1rem;
    &:hover {
      transform: scale(1.05);
    }
  }
  &--btnDelete {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    color: $secondary;
    border-radius: 50%;
    cursor: pointer;
  }
  &--modalTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.231818px;
    text-transform: uppercase;
    color: #000000;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &--modaltext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.231818px;
    color: $black;
    margin: 1rem 0;
  }
  &--btns-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $grey;
    margin-top: 1rem;
    overflow: hidden !important;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  &--btn-annuler,
  &--btn-delete {
    flex: 1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.231818px;
    text-transform: uppercase;
    cursor: pointer;
  }
  &--btn-delete {
    color: $red;
  }
  &--btn-annuler {
    color: $primaryLight;
  }
}

//RESPONSIVE
@media only screen and (max-width: $xs) {
  .selectedFolder {
    &--list-container,
    &--container {
      padding: 0;
    }
    &--list-name {
      flex: 2.5;
      font-weight: 600;
    }
    &--list-date {
      flex: 1;
    }
  }
}

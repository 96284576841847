.selectedLayout {
    width: 80%;
    height: 100vh;
    margin: 0 auto;
    background: $secondary;
    border: 1px solid $secondary;
    box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.22);
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    &--header {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    &--container {
        width: 100%;
        height: auto;
        overflow-y: auto;
        padding: 0 1rem;
    }
}

//RESPONSIVE
@media only screen and (max-width: $xs) {
    .selectedLayout {
        width: 100%;
        box-sizing: none;
        padding: 1rem;
    }
}
.management {
    a {
        color: inherit;
        &:hover {
            text-decoration: inherit;
            color: inherit;
        }
    }
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0;
    &--btn-viewInfo {
        width: 200px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $secondary;
        box-shadow: 0px 1px 4px rgba(106, 106, 106, 0.25);
        border-radius: 50px;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.231818px;
        text-transform: capitalize;
        color: $black;
        transition: ease-in-out all 0.3s;
        margin-bottom: 2rem;
        img {
            margin-right: 1rem;
        }
        &:hover {
            transform: scale(1.05);
        }
    }
    &--list-container {
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
        padding: 1rem 0.5rem;
        margin: 1rem 0;
    }
    &--list {
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $secondary;
        box-shadow: 0px 1px 4px rgba(106, 106, 106, 0.25);
        border-radius: 50px;
        padding: 0 1rem;
        margin: 0.7rem 0;
    }
    &--list-name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.231818px;
        text-transform: capitalize;
        color: $black;
    }
    &--list-name {
        flex: 1;
    }
    &--list-icon {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.05);
        }
    }
    &--btnDelete {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $red;
        color: $secondary;
        border-radius: 50%;
        cursor: pointer;
    }
    &--form {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    &--inputLayout {
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0.5rem 0;
        label {
            font-family: "Poppins";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.231818px;
            color: #a8a8a8;
            margin-left: 1rem;
            text-transform: capitalize;
        }
        input {
            box-shadow: 0px 4px 14px rgba(33, 116, 161, 0.12);
            width: 100%;
            height: 37px;
            border: none;
            padding: 0 1rem;
            &::placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 32px;
                color: $black;
            }
        }
    }
    &--btns-modal {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $grey;
        margin-top: 1rem;
        overflow: hidden !important;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    &--btn-annuler,
    &--btn-adduser {
        flex: 1;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        font-family: "Poppins";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.231818px;
        text-transform: uppercase;
        cursor: pointer;
    }
    &--btn-adduser {
        color: #40b144;
        border-left: 1px solid $grey;
    }
    &--btn-annuler {
        color: $primaryLight;
    }
}